$style: update-button;

.#{$style} {
  border: none;
  outline: none;
  color: $N0;
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 4px;
  padding: 6px 24px;
  @include transition(all);
  width: 100%;
  @include media {
    width: fit-content;
  }
  &:before {
    content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -2px;
    left:-2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 4px;
  }
  &:active:after {
    background: #1d1d1d;
  }
  &.loading {
    color: $N100;
    background: transparent;
  }
  &.loading:after {
    background: transparent;
  }
  &.loading:before {
    opacity: 1;
  }
  &:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #111;
    left: 0;
    top: 0;
    border-radius: 4px;
  }
  @include media {
    &:hover:before {
      opacity: 1;
    }
  }
}
