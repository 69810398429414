$style: ScrollBackdrop;

.light {
  .#{$style} {
    &__top {
      background-color: $N0;
    }
  }
}

.dark {
  .#{$style} {
    &__top {
      background-color: $B111;
    }
  }
}

.#{$style} {
  content: '';
  position: fixed;
  z-index: -1;
  height: 100vh;
  width: 100%;
  @include position;
  
  &__top {
    height: 50vh;
    width: 100%;
    transition: background-color 0.2s ease;
  }
  &__bottom {
    height: 50vh;
    width: 100%;
    @extend %animate-gradient;
  }
}
