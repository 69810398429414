$style: app-footer;

.dark {
  .#{$style} {
    &__inner {
      background-color: $B111;
    }
    &__description {
      color: $N2;
      @include media {
        color: $N4;
      }
      &--link {
        color: $N0;
        &:after {
          @include media {
            background-color: $N0;
          }
        }
      }
    }
    &__date-card {
      color: $N4;
    }
    &__date {
      color: $N0;
    }
  }
}

.#{$style} {
  &__inner {
    background-color: $N0;
    transition: background-color 0.2s ease;
    padding: 24px;
    @include flex-col(space-between, stretch);
    @include media {
      @include flex-row(space-between, flex-end);
    }
  }
  &__icon {
    @include size(20px);
    filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.3));
  }

  &__icon-wrapper {
    margin-right: 12px;
  }

  &__author {
    padding: 18px 24px 32px;
    background-color: transparent;
    @include text($H200, 400, $N100);
    @include flex-row(center, center);
    @include media {
      padding: 12px 24px;
      justify-content: flex-start;
    }
  }

  &__author-link {
    background-color: transparent;
    padding: 0;
    font-weight: 600;
    color: inherit;
    @extend %link-underline;
  }

  &__description {
    font-size: $H400;
    font-family: $second-font;
    font-weight: 200;
    text-align: center;
    color: $N2;
    @include media {
      color: black;
    }
    line-height: 1.4;
    margin-bottom: 0;
    display: inline-block;
    transition: color 0.2s ease;
    &--eng {
      font-family: $base-font;
      font-weight: 600;
      @include media {
        font-size: $H400;
      }
    }
    &--mark {
      font-weight: 600;
    }
    &--link {
      @extend %link-underline;
      &:after {
        background-color: $N0;
        @include media {
          background-color: $N100;
        }
      }
    }
    @include media {
      font-size: $H300;
      text-align-last: left;
    }
  }
  &__date-card {
    align-self: flex-end;
    font-family: $second-font;
    font-weight: 100;
    font-size: $H200;
  }
  &__info-card {
    display: block;
    @extend %card;
    min-height: auto;
    background-color: $B3;
    padding: 12px 24px;
    margin-bottom: 24px;
    @include media {
      margin-bottom: 0;
      background-color: transparent;
      padding: 0;
      box-shadow: none;
    }
  }
  &__date {
    font-weight: 400;
  }
  &__switch-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 42px;
    @include media {
      display: none;
    }
  }
  &__switch {
    background-color: $B7;
    &.ant-switch-checked {
      background-color: $B3;
    }
  }
}
