@font-face {
  font-family: 'e-Ukraine-Head';
  src: url('../assets/fonts/dija/e-UkraineHead-Thin.woff2') format('woff2'),
      url('../assets/fonts/dija/e-UkraineHead-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'e-Ukraine-Head';
  src: url('../assets/fonts/dija/e-UkraineHead-UltraLight.woff2') format('woff2'),
      url('../assets/fonts/dija/e-UkraineHead-UltraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'e-Ukraine-Head';
  src: url('../assets/fonts/dija/e-UkraineHead-Regular.woff2') format('woff2'),
      url('../assets/fonts/dija/e-UkraineHead-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'e-Ukraine-Head';
  src: url('../assets/fonts/dija/e-UkraineHead-Light.woff2') format('woff2'),
      url('../assets/fonts/dija/e-UkraineHead-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

