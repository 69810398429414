%bb {
  border-bottom: 1px solid $N2;
}

:root {
  --theme-color-bg: white;
  --theme-shadow: 0 5px 10px rgba(116, 79, 79, 0.12);
}

:root.dark {
  --theme-color-bg: #111e47;
  --theme-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
}

%card {
  box-shadow: var(--theme-shadow);
  border-radius: 8px;
  min-height: 140px;
  font-size: $H400;
  transition-property: box-shadow, background-color;
  transition-duration: 0.2s;
  transition-timing-function: ease;
  background-color: var(--theme-color-bg);
}

%link-underline {
  position: relative;
  &:after {
    content: '';
    display: block;
    height: 1px;
    background-color: $N100;
    position: absolute;
    left: 0;
    width: 0;
    bottom: 0;
    @include transition(width);
  }
  &:hover {
    color: inherit;
    &:after {
      width: 100%;
    }
  }
}

@-webkit-keyframes AnimationGradient {
  0%{background-position:0% 81%}
  50%{background-position:100% 20%}
  100%{background-position:0% 81%}
}
@-moz-keyframes AnimationGradient {
  0%{background-position:0% 81%}
  50%{background-position:100% 20%}
  100%{background-position:0% 81%}
}
@keyframes AnimationGradient {
  0%{background-position:0% 81%}
  50%{background-position:100% 20%}
  100%{background-position:0% 81%}
}

%animate-gradient {
  background: linear-gradient(271deg, $R2, $Y2, $G1, #17a781, $B2);
  background-size: 1000% 1000%;
  animation: AnimationGradient 39s ease infinite;
}
