@keyframes color-transition {
  0% {
    background-color: $B2;
    border-color: $B2;
  }
  25% {
    background-color: $R2;
    border-color: $R2;
  }
  50% {
    background-color: $G1;
    border-color: $G1;
  }
  75% {
    background-color: $P1;
    border-color: $P1;
  }
  100% {
    background-color: $Y1;
    border-color: $Y1;
  }
}

$style: select-card;

.dark {
  .#{$style} {
    .ant-select-selector {
      background-color: $B8;
      border-color: $B5;
    }
    .ant-select-selection-item {
      color: $N1;
    }
    .ant-select-arrow {
      color: $B4;
    }
    &__dropdown {
      background-color: $B8;
      box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.5),
        0 6px 16px 0 rgba(0, 0, 0, 0.5), 0 9px 28px 8px rgba(0, 0, 0, 0.3);
      .ant-select-item {
        color: $N1;
      }

      .ant-select-item-option-active {
        color: black;
      }
    }
    &__popover {
      .ant-popover-inner {
        background-color: $B8;
        border-color: $B5;
      }
      .ant-popover-title {
        color: $N0;
        border-color: $B5;
      }
      .ant-popover-inner-content a {
        color: $N2;
      }
    }
  }
}

.#{$style} {
  @include media {
    position: relative;
    top: -32px;
  }
  &__title {
    display: none;
    @include media {
      display: block;
      font-family: $second-font;
      @include text($H400, 300);
      margin-bottom: 32px;
    }
  }
  &__row {
    @include flex-row(flex-start, center);
  }
  &__icon-exchange {
    margin: 0 16px;
  }
  &__icon-middle {
    @include svg(18px, $N0);
  }
  &__select {
    display: none;
    @include media {
      display: block;
      width: 220px;
    }
    @include media($screen-desktop) {
      width: 240px;
    }
    @include media($screen-desktop-large) {
      width: 280px;
    }
    .ant-select-selector {
      transition-property: background-color, border-color !important;
      transition-duration: 0.2s !important;
      transition-timing-function: ease !important;
      border-radius: 6px !important;
      border-width: 1px !important;
    }
    .ant-select-selection-item {
      @include transition(color);
    }
    .ant-select-arrow {
      @include transition(color);
    }
  }
  &__select-inner {
    position: relative;
    pointer-events: none;
    display: flex;
    justify-content: center;
  }
  &__select-wrapper {
    pointer-events: none;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    box-shadow: 0 -1px 6px 8px rgba(0, 0, 0, 0.11);
    z-index: 10;
    @include media {
      display: none;
    }
  }
  &__placeholder {
    width: 100%;
    border-top-width: 1.02px;
    background-color: $B2;
    outline: none;
    z-index: 2;
    pointer-events: none;
    font-family: $second-font;
    @include text($H200, 300, $N0);
    @extend %animate-gradient;
    position: relative;
    padding: 26px 12px 12px;
    @include flex-row(space-between, center);
    .select-card__caption,
    .select-card__icon-middle {
      transition: opacity 0.2s ease-in-out;
    }
    &._hidden {
      .select-card__caption,
      .select-card__icon-middle {
        opacity: 0;
        visibility: hidden;
      }
    }
  }
  &__computed-bar {
    @include flex-row(space-between, center);
    border-top-width: 1.02px;
    padding: 6px 12px;
    @extend %animate-gradient;
    font-family: $second-font;
    @include text($H200, 300, $N0);
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    @include media(480px) {
      display: none;
    }
  }
  &__logo {
    @include svg(14px, $N0);
    svg {
      fill: $N0;
    }
  }
  &__caption {
    position: absolute;
    z-index: 3;
    width: 100%;
    left: 0;
    right: 0;
    top: 6px;
    font-family: $second-font;
    @include text($H200, 300, $N0);
    text-align: center;
  }
  &__select-mobile {
    top: 0;
    opacity: 0;
    z-index: 10;
    position: absolute;
    width: 280px;
    height: 100%;
    font-size: 16px;
    background-color: transparent;
    border: none;
    box-shadow: none;
    border-top-width: 1.02px;
    border-radius: 4px;
    outline: none;
    padding: 6px 12px;
    pointer-events: all;
    font-family: $second-font;
    font-size: 16px;
    font-weight: 200;
    transition: opacity 0.2s ease-in-out;
    .ant-select-selection-search-input {
      text-align: center !important;
      position: relative !important;
      bottom: -10px !important;
      color: white !important;
      font-size: 16px !important;
    }
    .ant-select-selector {
      background-color: transparent !important;
      border: none !important;
      box-shadow: none !important;
      outline: none !important;
    }
    .ant-select-selection-item {
      text-align: center !important;
      padding-right: 0 !important;
      color: white !important;
      bottom: -10px !important;
    }
    &._open {
      opacity: 1;
      z-index: 100;
    }
  }
  &__select-mobile-popover {
    width: 100% !important;
    left: 0 !important;
    top: 58px !important;
  }
  &__icon {
    @include size(20px);
    &--m-right {
      margin-right: 6px;
    }
    &--m-left {
      margin-left: 6px;
    }
  }
  &__placeholder-item {
    @include flex-row(flex-start, center);
    flex: 1 1 calc(50% - 18px);
    &--right {
      justify-content: flex-end;
    }
  }
  &__countries {
    list-style: none;
    padding: 0;
  }
  &__popover {
    * {
      transition: none !important;
    }
    .ant-popover-inner-content {
      a:hover,
      a:focus {
        filter: contrast(1.1) brightness(1.1);
      }
    }
    .ant-popover-inner {
      border-radius: 6px;
    }
    .ant-popover-title {
      font-weight: 600;
    }
  }
  &__currency {
    font-family: $second-font;
    font-weight: 100;
  }
  &__dropdown {
    border-radius: 6px !important;
  }
}
