.dark {
  .app-header {
    background-color: $B111;
    &__title {
      color: $N0;
    }
    &__nav {
      border-color: $B8;
    }
    @include media {
      border-color: $B8;
    }
  }
}
.app-header {
  height: 82px;
  position: relative;
  background-color: $N0;
  transition-property: background-color, border-color;
  transition-duration: 0.2s;
  transition-timing-function: ease;
  @include media {
    height: auto;
    border-bottom: 1px solid $N1;
    margin-bottom: 0;
    padding-bottom: 62px;
  }
  &__help {
    text-align: center;
    left: 0;
    right: 0;
    position: fixed;
    top: 60px;
    font-family: $second-font;
    @include text($H300, 200, $N6);
    z-index: 0;
    @include media {
      display: none;
    }
  }
  .app-carousel {
    display: none;
    @include media {
      display: block;
    }
  }
  &__logo {
    @include svg(20px);
    margin-right: 8px;
    @include media {
      @include svg(32px);
      display: block;
      margin-right: 12px;
    }
  }
  &__nav {
    @include container;
    $size: 12px;
    padding-top: $size;
    padding-bottom: $size;
    @extend %bb;
    margin: 0;
    display: none;
    transition: border-color 0.2s ease;
    @include media {
      display: flex;
      justify-content: space-between;
      align-items: center;
      $size: 14px;
      padding-top: $size;
      padding-bottom: $size;
    }
  }
  &__link {
    @include transition(color);
    @include flex-row(flex-start, center);
    @include media {
      &:hover > * {
        color: lighten($N100, 20%);
      }
    }
    &:active > * {
      color: $N100;
    }
  }
  &__title {
    @include text($H500, 600);
    transition: color ease 0.2s;
    color: $N100;
    margin: 0;
    font-family: $pixel-font;
    @include media {
      font-size: $H900;
    }
  }
  &__switch {
    background-color: $B7;
    &.ant-switch-checked {
      background-color: $B3;
    }
  }
}