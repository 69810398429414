@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&family=Pixelify+Sans:wght@400;600&display=swap');
@import './fonts';
@import './vars';
@import './mixins';
@import './inheritance';

@import './common';

@import './components/ScrollBackdrop';

@import './components/animate';
@import './components/update-button';
@import './components/app';
@import './components/home-page';
@import './components/app-header';
@import './components/app-carousel';
@import './components/app-slide';
@import './components/exchange-card';
@import './components/exchange-card-currency';
@import './components/select-card';
@import './components/ontop-select';
@import './components/app-footer';