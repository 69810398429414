
@mixin white-gradient {
	background: linear-gradient(to right,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
}

@mixin dark-gradient {
	background: linear-gradient(to right,  $B11 0%, rgba(255,255,255,0) 100%);
}

$animationSpeed: 60s;

// Animation
@keyframes scroll {
	0% { transform: translateX(0); }
	100% { transform: translateX(calc((-300px - 64px) * 8))}
}

// Styling

.dark {
  .app-carousel {
    background: $B111;
		&::before,
		&::after {
			@include dark-gradient;
		}
  }
}


.app-carousel {
	background: white;
	transition: background 0.2s ease;
	margin: auto;
	overflow:hidden;
	position: relative;
	&::before,
	&::after {
		@include white-gradient;
		height: 100%;
		position: absolute;
		width: 200px;
		z-index: 2;
	}
	
	&::after {
		right: 0;
		top: 0;
		transform: rotateZ(180deg);
	}

	&::before {
		left: 0;
		top: 0;
	}

	@include media {
		&::before,
		&::after {
			content: "";
		}
	}
	
	&__track {
		animation: scroll $animationSpeed linear infinite;
		display: flex;
    width: 100vw;
    list-style: none;
	}
}
